@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: local('Raleway Thin'), local('Raleway-Thin'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptsg8zYS_SKggPNwE44TYFv.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwOIpWqZPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwIYqWqZPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ISg.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Medium'), local('Raleway-Medium'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwN4rWqZPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwPIsWqZPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqZPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwIouWqZPBQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: local('Raleway Black'), local('Raleway-Black'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vWqZPBQ.ttf) format('truetype');
}
.font {
  font-family: 'Raleway', sans-serif;
}
#video-soprano {
  margin: 0 auto;
  display: block;
  width: 50%;
}
@media all and (max-width: 800px) {
  #video-soprano {
    width: 100%;
  }
}
#inicio-top {
  position: relative;
}
#inicio-top .go {
  display: block;
  position: absolute;
  height: 250px;
  width: 250px;
  border: none;
  right: 7%;
  background: transparent;
  bottom: 2%;
}
h3 {
  font-size: 17px;
  font-weight: 400 !important;
}
li {
  list-style: none;
  list-style-position: inside;
}
h1,
h2,
h3 {
  font-weight: 200;
}
body {
  font-family: 'Raleway', sans-serif;
  background: #D7EAD9;
}
body p {
  text-align: justify;
}
#inicio-sobre {
  padding: 5em 0;
}
#centro {
  background: white;
  box-shadow: 0 0 2px grey;
}
#menu {
  background: rgba(251, 251, 255, 0.6);
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}
#menu a {
  font-size: 12px;
  text-transform: uppercase;
  color: black;
}
#menu a:hover {
  background: rgba(244, 244, 244, 0.81);
}
#menu .cont-botones {
  text-align: center;
  display: flex;
}
#menu .cont-botones #botones {
  display: block;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
#menu .cont-botones #botones li {
  float: left;
  margin: 0;
  padding: 0 12px;
}
#menu .cont-botones #botones li a {
  line-height: 1;
  display: block;
  text-align: center;
}
#menu .cont-botones #botones li a:hover,
#menu .cont-botones #botones li a:active,
#menu .cont-botones #botones li a:focus {
  text-decoration: none;
}
@media (max-width: 425px) {
  #menu {
    display: none;
  }
}
#menu-mobile {
  margin-bottom: 0;
  display: none;
}
#menu-mobile a,
#menu-mobile img,
#menu-mobile li {
  text-align: center !important;
}
@media (max-width: 425px) {
  #menu-mobile {
    display: block;
  }
}
#menu-mobile .navbar-brand {
  padding-top: 0.3em !important;
  margin-top: 0 !important;
}
#videos {
  padding: 3em 0.5em;
}
#videos h3,
#videos h2 {
  margin-bottom: 1em;
}
#videos video {
  padding: 0.4em;
  background: white;
  box-shadow: 0 0 2px grey;
}
footer {
  margin-top: 2em;
  background: #ECECEC;
}
footer form {
  /* WebKit */
  /* Firefox 18- */
  /* Firefox 19+ */
  /* IE 10+ */
}
footer form input,
footer form textarea {
  color: grey !important;
  background: white !important;
}
footer form ::-webkit-input-placeholder {
  color: grey !important;
  font-weight: 100;
}
footer form :-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 100;
}
footer form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 100;
}
footer form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 100;
}
footer a,
footer p,
footer i {
  color: black;
  font-size: 11px;
}
footer .form-control {
  background: transparent;
  border-radius: 0;
  margin: 0.3em 0;
}
footer h1,
footer h2,
footer h3 {
  color: black;
}
footer h3 {
  font-weight: 200;
  font-size: 18px;
  border-bottom: 1px solid black;
  display: block;
  padding-bottom: 0.3em;
  text-transform: uppercase;
}
footer .container {
  padding: 2em;
}
.line {
  width: 100%;
  height: 20px;
  background: #e29115;
}
.form-control {
  border-radius: 0.2em !important;
  margin: 0.2em 0;
}
.button {
  background: #0097E5;
  border-radius: 0.1em;
  margin: 0.2em 0;
  border: none;
}
#contacto {
  padding: 1.5em 0;
}
#sobre #text {
  padding: 3em;
}
#sobre #logo {
  padding: 3em 0;
}
.titulo {
  position: absolute;
  right: 3%;
  bottom: 4%;
  text-transform: uppercase;
  font-size: 4.5rem;
  color: white;
  text-shadow: 0 0 3px black;
  font-weight: 300;
}
@media (max-width: 400px) {
  .titulo {
    font-size: 2.5rem;
  }
}
#depilacion #text {
  padding-top: 3.5em;
}
#depilacion #items li {
  padding: 0.3em;
}
#sesiones p {
  line-height: 2em;
}
#sesiones li {
  padding: 0.3em;
}
.imagen-zona {
  position: absolute;
  left: 0;
  top: 0;
}
.imagen-zona img {
  max-height: 600px;
}
#botones li a {
  padding: 22px 6px !important;
  font-size: 12px !important;
}
@media (max-width: 1200px) {
  #botones li a {
    font-size: 11px !important;
  }
}
@media (max-width: 768px) {
  #botones li a {
    font-size: 10px !important;
    font-weight: 700;
  }
}
.slider {
  height: auto;
}
.slider img {
  max-height: 350px;
  width: 100%;
}
button.slick-prev {
  left: 3%;
  z-index: 999;
}
button.slick-prev:before {
  text-shadow: 0 0 4px black;
}
button.slick-next {
  right: 3%;
  z-index: 999;
}
button.slick-next:before {
  text-shadow: 0 0 4px black;
}
.turno {
  margin: 0.4em 0;
}
#table-sucursales {
  height: 400px;
  overflow: auto;
}
.atendido {
  background: green;
  color: whitesmoke;
}
.cancelado {
  background: red;
  color: white;
}
.espera {
  background: lightgrey;
  color: black;
}
.modal.modal-wide .modal-dialog {
  width: 80%;
}
#promo {
  position: absolute;
  right: 40%;
  bottom: 25px;
}
#promo img {
  height: 180px;
}
#panel {
  position: absolute;
  padding: 1.5em;
  left: 0.3em;
  font-size: 1.3em;
  top: 0.3em;
  background: #ebebeb;
  text-transform: uppercase;
}
.sobreturno {
  background: gold;
}
.header-top {
  display: block;
  text-align: center;
  padding: 10px;
}
.header-top a {
  display: block;
  text-align: center;
}
.header-top a img {
  margin: 0 auto;
  display: block;
}
.header-top h5 {
  margin: 15px 0 0 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}
